<template>
  <form method="POST" action="https://yoomoney.ru/quickpay/confirm.xml">
    <input type="hidden" name="receiver" :value="yoomoney" />
    <input type="hidden" name="quickpay-form" value="shop" />
    <input type="hidden" name="targets" :value="dataStudio.studioName" />
    <input type="hidden" name="paymentType" value="AC" />
    <input type="hidden" name="sum" :value="priceRu" data-type="number" />
    <!--      не обяязательно-->
    <input type="hidden" name="formcomment" :value="`Metamorfosi: ${dataStudio.studioName}`" />
    <input type="hidden" name="short-dest" :value="`Metamorfosi: ${dataStudio.studioName}`" />
    <input
      type="hidden"
      name="label"
      :value="`${user.id}/${dataStudio.id}/${dataPrice.titleStudiosPrice}/${dataPrice.anteaters}`"
    />
    <input type="hidden" name="comment" :value="dataPrice.status" />
    <input type="hidden" name="successURL" :value="successURL" />
    <input type="hidden" name="need-fio" value="true" />
    <input type="hidden" name="need-email" value="true" />
    <input type="hidden" name="need-phone" value="false" />
    <input type="hidden" name="need-address" value="false" />
    <v-btn type="submit" :x-small="$vuetify.breakpoint.xs" :loading="loading" color="#FB8C00"
      >Купить</v-btn
    >
  </form>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'JuMoney',
  props: {
    dataPrice: {
      type: Object,
      required: true
    },
    dataStudio: {
      type: Object,
      required: true
    },
    authorEmail: {
      type: String,
      required: true
    }
  },
  data: () => ({
    exchange: 80
  }),
  computed: {
    ...mapState({
      loading: state => state.shared.loading,
      user: state => state.user.user
    }),
    priceRu() {
      return this.dataPrice.price * this.exchange * 1.02
    },
    successURL() {
      if (this.dataPrice.typeExercise === 'studios') {
        return `https://metamorfosi.eu/studios/home/${this.dataPrice.titleStudiosPrice}`
      } else if (this.dataPrice.typeExercise === 'studiosGroup') {
        return `https://metamorfosi.eu/studios/group/${this.dataPrice.titleStudiosPrice}`
      } else if (this.dataPrice.typeExercise === 'calendars') {
        return `https://metamorfosi.eu/calendar/${this.dataPrice.titleStudiosPrice}`
      } else if (this.dataPrice.typeExercise === 'individualLesson') {
        return `https://metamorfosi.eu/thankYoy/${this.dataPrice.titleStudiosPrice}`
      } else {
        return `https://metamorfosi.eu/artTherapyDescription/${this.dataPrice.titleStudiosPrice}`
      }
    },
    yoomoney() {
      return this.authorEmail === 'ekuchina@yandex.ru' ? '4100117798721789' : '410015516306516'
    }
  }
}
</script>

<style scoped></style>
